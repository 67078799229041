import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import DittoGif from "../component/image/DittoGif";
import CustomeButton from "../component/button/CustomeButton";

interface MemePageProps {

}

const MemePage: React.FC<MemePageProps> = (props: MemePageProps) => {
    const audio = new Audio("https://tschei.max-sahm.de/fury.mp3")
    const img = new Image()
    img.src = "https://tschei.max-sahm.de/ditto1.gif"
    const [first, setFirst] = useState<boolean>(false);
    const [isButtonClicked, setButtonClicked] = useState<boolean>(false);
    const [isButtonClickable, setIsButtonClickable] = useState<boolean>(false);


    const handleOnClick = () => {
        audio.play();
        setButtonClicked(!isButtonClicked);
    }

    useEffect(() => {
        const handleImageLoad = () => {
            if(first){
                setIsButtonClickable(true);
            } else {
                setFirst(true);
            }
        }

        const handleAudioLoad = () => {
            if(first){
                setIsButtonClickable(true);
            } else {
                setFirst(true);
            }
            
        }

        img.addEventListener("load", handleImageLoad)
        audio.addEventListener("canplaythrough", handleAudioLoad)
    })

    


    return(
        <Grid container 
        justifyContent={"center"} 
        alignItems={"center"} 
        direction={"row"}
        height={"100vh"}>

            {!isButtonClicked && !isButtonClickable && (
                <Grid item>
                    <img src="Loading_icon.gif"></img>
                </Grid>
            )}

            {!isButtonClicked && isButtonClickable && (
            <Grid item>
                 <CustomeButton onClick={handleOnClick}></CustomeButton>
            </Grid>
            )}
            {isButtonClicked && (
            <Grid item width={"100vw"} height={"100vh"}>
                <DittoGif/>
            </Grid>
            )}
        </Grid>
    )
}

export default MemePage;
