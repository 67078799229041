import * as React from 'react';
import Button from '@mui/material/Button';

interface CustomeButtonProps {
    onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
}

export const CustomeButton: React.FC<CustomeButtonProps> = (props : CustomeButtonProps) => {
    return(
        <Button variant="contained" onClick={props.onClick}>Trust me</Button>
    )
}

export default CustomeButton